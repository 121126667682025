import React, { MouseEvent, useState, useRef } from 'react';
import { SanityProductDisclaimer } from 'graphql-types';

import { Container } from './Prop65Block.styles';
import {
  SanityRichText,
  Mark,
  MarkProps,
} from 'components/Common/SanityRichText';
import { WarningModal } from 'components/WarningModal/WarningModal';
import { A } from 'components/Common/SanityRichText/SanityRichText.styles';
import { PDPPageContainer } from 'components/Pdp';

// #region Prop 65 Link Serializer
const Prop65Link: React.FC<
  Mark<{
    color?: {
      hex: string;
    };
  }> & { onClick?: React.MouseEventHandler }
> = ({ children, onClick, mark, _key }) => {
  return (
    <A key={_key} onClick={onClick} color={mark.color?.hex}>
      {children}
    </A>
  );
};

export const Prop65Block: React.FC<{
  disclaimer?: Pick<
    SanityProductDisclaimer,
    '_rawContent' | 'name' | '_rawPageContent'
  >;
}> = ({ disclaimer }) => {
  const [showModal, setShowModal] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);
  if (!disclaimer) {
    return null;
  }

  const { name, _rawContent, _rawPageContent } = disclaimer;

  const linkSerializer = (
    props: MarkProps<{
      color?: {
        hex: string;
      };
    }>
  ) => {
    return <Prop65Link {...props} onClick={modalClickHandler} />;
  };

  const markSerializerTypes = {
    link: linkSerializer,
    coloredLink: linkSerializer,
  };

  const modalClickHandler = (e: MouseEvent) => {
    e.preventDefault();
    setShowModal(!showModal);
  };

  return (
    <Container ref={containerRef}>
      <PDPPageContainer>
        <SanityRichText
          blocks={_rawPageContent}
          markSerializerTypes={markSerializerTypes}
        />
      </PDPPageContainer>

      {showModal && (
        <WarningModal
          onOverlayClick={modalClickHandler}
          onBtnCloseClick={modalClickHandler}
          showBtnClose={true}
          title={name}
          body={<SanityRichText blocks={_rawContent} />}
        ></WarningModal>
      )}
    </Container>
  );
};
