import React from 'react';
import { graphql } from 'gatsby';
import {
  OpenfitBundleProductPageBySlugQuery,
  SanityOpenfitBundle,
} from 'graphql-types';
import { SeoMetadata } from 'components/SeoMetadata/SeoMetadata';
import { renderBundlePageBlock } from './BundleProductPage.blocks';
import { PDPPageContainer } from 'components/Pdp';
import { Breadcrumb } from 'components/Product/Breadcrumb/Breadcrumb';
import { useCollection } from 'utils/url';
import { useLogPageViewed } from 'helpers/Amplitude';
import { useTagStandardPageView } from 'helpers/Tealium';
import { useGTMTagStandardPageView } from 'helpers/GTM';
import {
  BundleDisplayMethod,
  BundleProductProvider,
  BundleProducts,
} from 'Context/BundleProduct/BundleProduct.context';
import { Loading } from 'components/Common/Loading';
import { ImageLoadingWrapper } from 'components/Common/GatsbyImage';
import { useUserData } from '@bbnb/openfit-frontend-shared';

interface OpenfitBundleProductTemplateProps {
  data: OpenfitBundleProductPageBySlugQuery;
}

const includesDigitalSubscription = (products: BundleProducts) =>
  !!products.find(
    (product) => product?.product?.name === 'Openfit Subscription'
  );

export const OpenfitBundleProductTemplate: React.FC<OpenfitBundleProductTemplateProps> = ({
  data,
}) => {
  const {
    slug,
    bundle,
    oneTimeBundle,
    blocks,
    defaultCollection,
    defaultCollectionAnchor,
    seoMetadata,
    displayMethod,
    name = '',
  } = data.page || {};
  const { products, bundleId } = (bundle || {}) as SanityOpenfitBundle;

  useLogPageViewed();
  useTagStandardPageView('page_view');
  useGTMTagStandardPageView('page_view');

  const { loading, role } = useUserData();

  if (!products || !products.length) {
    throw new Error('Products not returned with query');
  }

  if (!bundleId) {
    throw new Error('BundleId is required');
  }

  const { collectionTitle, collectionUrl } = useCollection(
    defaultCollection,
    defaultCollectionAnchor
  );

  return (
    <>
      {seoMetadata && (
        <SeoMetadata
          metaData={seoMetadata}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          slug={`products/${slug!.current!}`}
        />
      )}
      <PDPPageContainer>
        <Breadcrumb
          collectionTitle={collectionTitle}
          collectionUrl={collectionUrl}
          pageTitle={name}
        />
        {loading && <Loading />}
      </PDPPageContainer>
      <BundleProductProvider
        displayMethod={displayMethod as BundleDisplayMethod}
        bundle={bundle as SanityOpenfitBundle}
        oneTimeBundle={oneTimeBundle as SanityOpenfitBundle | undefined}
      >
        {blocks
          ?.map((block) => {
            if (!block) {
              return null;
            }

            return renderBundlePageBlock(
              block,
              data.page,
              includesDigitalSubscription(products as BundleProducts),
              role
            );
          })
          .map((block, index) => (
            <ImageLoadingWrapper
              key={index}
              eager={index < 2}
              children={block}
            />
          ))}
      </BundleProductProvider>
    </>
  );
};

export default OpenfitBundleProductTemplate;

export const query = graphql`
  query OpenfitBundleProductPageBySlug($slug: String!) {
    page: sanityOpenfitBundlePage(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      name
      seoMetadata {
        ...SeoMetadata
      }
      defaultCollection {
        slug {
          current
        }
        name
      }
      defaultCollectionAnchor
      displayMethod
      ...BundleProductInfo
      ...BundleProductPageBlocks
    }
  }
`;
