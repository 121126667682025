import styled from 'styled-components';
import savingsSvg from 'svg/savings.svg';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.priceViolator.background};
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  mask-image: url(${savingsSvg.url});
  width: 115px;
  height: 115px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

export const OriginalPriceText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  line-height: 1;

  span {
    position: relative;

    &:after {
      left: 0;
      right: 0;
      height: 1px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      background: ${({ theme }) => theme.colors.red800};
      position: absolute;
    }
  }
`;

export const CurrentPriceText = styled.p`
  font-size: 26px;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeights.black};

  sup {
    font-size: 14px;
    line-height: 20px;
    vertical-align: super;
    position: relative;
    top: 2px;
  }
`;

export const SavingsTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  line-height: 1;
`;
