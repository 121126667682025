import styled from 'styled-components';
import { space } from 'styled-utils';

export const Container = styled.div`
  ${space({ px: ['1em', 0, 0] })};
  margin-top: 32px;

  p.textSmall {
    text-align: center;
    @media (min-width: 980px) {
      text-align: left;
    }
  }
`;
