import React, { useEffect, useMemo } from 'react';
import { GatsbyImageProps } from 'gatsby-plugin-image';
import { SanityImageAssetWithPlaceholder } from 'types';
import {
  BuyBoxContainer,
  Container,
  OptionsContainer,
  OptionsElement,
  TitleContainer,
  ProductImageContainer,
  TabletDescriptionContainer,
  SavingsContainer,
  VariantOptionsContainer,
  VariantTypeStyle,
} from './BundleAddToCartBlock.styles';
import {
  ProductProvider,
  useProductContext,
} from 'Context/Product/Product.context';
import { VariantGroupSelector } from '../VariantGroupSelector/VariantGroupSelector';
import { getProductContextData } from 'Context/Product/Product.helper';
import { useBundleProductContext } from 'Context/BundleProduct/BundleProduct.context';
import { StickyBuyButtonCta } from '../StickyBuyButtonCta/StickyBuyButtonCta';

import {
  BundleProductInfoFragment,
  SanityImage,
  SanityProductDescription,
} from 'graphql-types';
import {
  BundleProductItem,
  getBundleItemParamKey,
  getProductFromBundleProducts,
} from 'Context/BundleProduct/BundleProduct.helper';
import { PDPPageContainer } from 'components/Pdp';
import { useLocation } from '@reach/router';
import { createCheckoutUrl } from 'utils/url';
import { Rating, RatingText } from '../AddToCartBlock/AddToCartBlock.styles';
import { StarRating } from '../StarRating/StarRating';
import { awaitClick, logEvent } from 'helpers/Amplitude';
import { tagCartAdd } from 'helpers/Tealium';
import { GTMTagCartAdd } from 'helpers/GTM';
import { ProductDescription } from './ProductDescription';
import { Spacer } from 'components/Spacer/Spacer';
import { Savings } from './Savings';
import { getReviewsData } from 'helpers/Yotpo';
import { useIsTablet } from 'hooks/use-media-query';
import { DigitalSubscriptionWarningModal } from 'components/DigitalSubscriptionWarningModal/DigitalSubscriptionWarningModal';
import { ProductImageMultiple } from 'components/Product/ProductImageMultiple';
import { Prop65Block } from 'components/Product/Prop65Block/Prop65Block';
import { useCart } from 'hooks/use-cart';
import { SubscribeAndSaveBundle } from '../SubscribeAndSave/SubscribeAndSaveBundle';
import { VariantSwatch } from '../VariantSwatch/VariantSwatch';
import {
  useIsBrowser,
  coupon as getCoupon,
} from '@bbnb/openfit-frontend-shared';
import { VariantOptionSelectorTitle } from '../VariantOptionSelector/VariantOptionSelector.styles';

export interface BundleAddToCartBlockProps {
  title?: string;
  images: NonNullable<BundleProductInfoFragment['bundle']>['images'];
  description?: SanityProductDescription;
  couponCode?: string;
  addToCartAction?: 'checkout' | 'cart';
}

function generateBuyUrl(bundleId: string, bundleItems: BundleProductItem[]) {
  const product = bundleItems.reduce((prev, { sku }) => {
    prev += sku ? `_${sku}` : '';
    return prev;
  }, bundleId);

  const checkoutParams: { product: string; coupon?: string } = {
    product,
  };

  const coupon = getCoupon();
  if (coupon) {
    checkoutParams.coupon = coupon;
  }

  return createCheckoutUrl(checkoutParams);
}

export const BundleAddToCartBlock: React.FC<BundleAddToCartBlockProps> = ({
  title,
  images = [],
  couponCode,
  description,
  addToCartAction,
}) => {
  const { isAdding, addToCart } = useCart({
    lazyLoad: addToCartAction === 'cart',
  });
  const {
    selectedBundleItemMap,
    review,
    staticReviewData,
    bundleId,
    bundleProducts,
    bundleItems,
    price,
    origPrice,
    subscriptionIntervals,
  } = useBundleProductContext();

  // #region update query string with sku selection
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const location = useLocation();
  const isTablet = useIsTablet();

  useEffect(() => {
    let didItemSkuChange = false;
    const bundleItemKeys = Object.keys(selectedBundleItemMap);
    bundleItemKeys.forEach((bundleGroupName) => {
      const bundleItemSkuKey = getBundleItemParamKey(bundleGroupName);
      const urlItemSku = searchParams.get(bundleItemSkuKey);
      const currentItemSku = selectedBundleItemMap[bundleGroupName].sku;
      if (currentItemSku && currentItemSku !== urlItemSku) {
        didItemSkuChange = true;
        searchParams.set(bundleItemSkuKey, currentItemSku);
      }
    });
    const state = location.state ? (location.state as Record<string, any>) : {};

    if (didItemSkuChange) {
      window.history.replaceState(state, '', `${pathname}?${searchParams}`);
    }
  }, [selectedBundleItemMap]);
  // #endregion

  const buyUrl =
    addToCartAction !== 'cart'
      ? generateBuyUrl(bundleId, bundleItems)
      : undefined;

  const gatsbyImages: GatsbyImageProps[] = [];
  const gatsbyImagesPlaceholders: GatsbyImageProps[] = [];

  for (const standardImage of images) {
    if (!standardImage) continue;
    const image = standardImage.image as SanityImage;
    const gatsbyImage = image?.asset?.gatsbyImageData;
    const gatsbyImagePlaceholder = (image?.asset as SanityImageAssetWithPlaceholder)
      ?.placeholder;
    const alt = standardImage.alt || title || '';
    if (gatsbyImage) {
      gatsbyImages.push({
        image: gatsbyImage,
        alt,
      });
      gatsbyImagesPlaceholders.push({
        image: gatsbyImagePlaceholder || gatsbyImage,
        alt,
      });
    }
  }

  const handleBuyButton: (isSticky?: boolean) => React.ReactEventHandler = (
    isSticky
  ) => {
    const action = addToCartAction === 'cart' ? 'Add To Cart' : 'CTA';
    const sticky = isSticky ? ' Sticky' : '';
    const amplitudeEventName = `Bundle Product Page:${sticky} ${action} Clicked`;

    const products = bundleItems.map((item) => {
      const { sku, name, price, productId } = item;
      return {
        id: productId,
        sku,
        name,
        price,
      };
    });

    return awaitClick(() => {
      return Promise.all([
        GTMTagCartAdd(products),
        tagCartAdd(products),
        logEvent(amplitudeEventName, {
          name: bundleId,
          sku: products.map(({ sku }) => sku),
          price,
        }),
        addToCartAction === 'cart'
          ? addToCart({
              id: products.reduce((prev, { sku }) => {
                prev += sku ? `_${sku}` : '';
                return prev;
              }, bundleId),
              quantity: 1,
              coupon: getCoupon() || undefined,
            })
          : Promise.resolve(),
      ]);
    });
  };

  const { displayRating, score, totalReviews } = getReviewsData(
    review,
    staticReviewData
  );

  const productWithDisclaimer = bundleProducts?.find(
    (bundleProduct) =>
      bundleProduct?.product?.disclaimer?.name === 'California Prop 65'
  );

  return (
    <PDPPageContainer>
      <Container>
        <ProductImageContainer>
          {Boolean(gatsbyImages.length) && (
            <ProductImageMultiple
              images={gatsbyImages}
              placeholders={gatsbyImagesPlaceholders}
            />
          )}
          {price !== origPrice && (
            <SavingsContainer>
              <Savings currentPrice={price} originalPrice={origPrice} />
            </SavingsContainer>
          )}
        </ProductImageContainer>
        <BuyBoxContainer>
          <TitleContainer>
            <h1>{title}</h1>
            {displayRating && (
              <Rating href="#reviews">
                <StarRating rating={score} />
                <RatingText>{`${score} of 5.0 (${totalReviews} Reviews)`}</RatingText>
              </Rating>
            )}
          </TitleContainer>
          <OptionsContainer>
            <BundleProductBlock searchParams={searchParams} />
          </OptionsContainer>
          {Boolean(subscriptionIntervals) && <SubscribeAndSaveBundle />}
          <StickyBuyButtonCta
            handleBuyClick={handleBuyButton()}
            handleBuyStickyClick={handleBuyButton(true)}
            price={price}
            title={title}
            buyUrl={buyUrl}
            origPrice={origPrice}
            addToCartAction={addToCartAction}
            disabled={isAdding}
          />
          <Prop65Block
            disclaimer={productWithDisclaimer?.product?.disclaimer}
          />
          {description ? (
            !isTablet ? (
              <ProductDescription description={description} />
            ) : (
              <Spacer margin={24} />
            )
          ) : null}
        </BuyBoxContainer>
      </Container>
      {description &&
        (isTablet ? (
          <TabletDescriptionContainer>
            <ProductDescription description={description} />
          </TabletDescriptionContainer>
        ) : null)}
      <DigitalSubscriptionWarningModal />
    </PDPPageContainer>
  );
};

const BundleProductBlock: React.FC<{ searchParams: URLSearchParams }> = ({
  searchParams,
}) => {
  const { bundleGroupMap } = useBundleProductContext();

  return useMemo(() => {
    if (!bundleGroupMap) {
      return null;
    }
    return (
      <>
        {Object.keys(bundleGroupMap).map((bundleGroupName) => {
          const bundleProducts = bundleGroupMap[bundleGroupName];
          const hasMultipleGroup = bundleProducts.length > 1;
          const hasMultipleSkus =
            Array.isArray(bundleProducts) &&
            (bundleProducts[0]?.skus?.length || 0) > 1;

          if (hasMultipleGroup || hasMultipleSkus) {
            const product = getProductFromBundleProducts(bundleProducts);

            if (!product) {
              return null;
            }

            // Determine initial sku for this product context
            const intialSku =
              searchParams.get(getBundleItemParamKey(bundleGroupName)) ||
              undefined;

            const bundledProductContextData = getProductContextData(
              product,
              intialSku
            );

            return (
              <ProductProvider
                key={intialSku}
                {...bundledProductContextData}
                product={product}
                variantGroupSelectionType={bundleGroupName + ' Flavor:'}
              >
                <BundleProductSelector
                  hasMultipleGroup={hasMultipleGroup}
                  bundleGroupName={bundleGroupName}
                />
              </ProductProvider>
            );
          }
          return null;
        })}
      </>
    );
  }, [bundleGroupMap]);
};

const useHandleBundleUpdate = (bundleGroupName: string) => {
  const { selectedVariant } = useProductContext();
  const {
    updateSelectedBundleItem,
    selectedBundleItemMap,
  } = useBundleProductContext();

  useEffect(() => {
    // Update bundle context with selected variant
    const item = selectedBundleItemMap[bundleGroupName];
    const selectedSku = selectedVariant.sku;
    if (selectedSku && item.sku !== selectedSku) {
      updateSelectedBundleItem(bundleGroupName, selectedVariant);
    }
  }, [selectedVariant]);
};

const BundleProductSelector: React.FC<{
  hasMultipleGroup: boolean;
  bundleGroupName: string;
}> = ({ hasMultipleGroup, bundleGroupName }) => {
  const {
    variantGroupSelectors,
    variantOptions,
    selectedVariant,
    setSelectedVariantOption,
  } = useProductContext();
  const isBrowser = useIsBrowser();
  const { selectedInterval } = useBundleProductContext();

  useHandleBundleUpdate(bundleGroupName);

  return (
    <>
      {variantGroupSelectors.length > 1 && (
        <OptionsElement>
          <VariantGroupSelector />
        </OptionsElement>
      )}
      {hasMultipleGroup && (
        <OptionsElement>
          <VariantOptionSelectorTitle>
            {`${bundleGroupName}-Type`}:
          </VariantOptionSelectorTitle>
          <VariantOptionsContainer>
            {variantOptions.options.map(({ text, sku, subscriptionSkus }) => {
              if (text.includes(bundleGroupName)) {
                text = text.replace(bundleGroupName, '').trim();
              }

              return (
                <VariantSwatch
                  key={sku + isBrowser.toString()}
                  isActive={
                    sku === selectedVariant.sku ||
                    subscriptionSkus.includes(selectedVariant.sku)
                  }
                  description={<VariantTypeStyle>{text}</VariantTypeStyle>}
                  onClick={() => {
                    const option = variantOptions.options.find(
                      (variantOption) => variantOption.sku === sku
                    );

                    if (option) {
                      setSelectedVariantOption(option, selectedInterval);
                    }
                  }}
                />
              );
            })}
          </VariantOptionsContainer>
        </OptionsElement>
      )}
    </>
  );
};
