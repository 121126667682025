import styled from 'styled-components';
import { typography } from 'styled-utils';

export const VariantSwatchSelectionTextStyles = styled.div`
  width: 150px;
  margin-top: 0.25rem;
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
`;

export const VariantSwatchStyles = styled.div<{
  color?: string;
  isActive: boolean;
  isValid?: boolean;
}>`
  outline: none;
  cursor: pointer;
  border: 3px solid white;
  height: 37px;
  ${({ theme }) => typography(theme.typography.xSmall)};
  ${({ color, isActive, theme, isValid = true }) => {
    return `${
      color
        ? `background:${color};
        box-sizing: border-box;
        width: 37px;

        `
        : `display: flex;
          border: 1px solid ${theme.colors.basic400};
          align-items: center;
          justify-content: center;
          padding-left: 7.5px;
          padding-right 7.5px;
          height: 40px;
          color: ${theme.colors.basic800};
        `
    }
    ${
      isActive
        ? `
        ${
          color
            ? `padding: 0; box-shadow: inset 0 0 0 2px ${theme.colors.basic100};`
            : `background: ${theme.variantSwatch.activeBackground};`
        }
        border: 1px solid ${theme.colors.blue400};`
        : ''
    }

    ${
      !isValid
        ? `
        color: ${theme.colors.basic600};
        border-style: dashed;`
        : ''
    }

    border-radius: ${color ? '2rem;' : '4px;'};`;
  }}
`;
