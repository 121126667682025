import React from 'react';
import {
  VariantSwatchStyles,
  VariantSwatchSelectionTextStyles,
} from './VariantSwatch.styles';

export const VariantSwatch: React.FC<{
  color?: string;
  isActive: boolean;
  isValid?: boolean;
  description?: string | JSX.Element;
  onClick?:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
}> = ({ isActive, color, onClick, description, isValid, children }) => {
  return (
    <div>
      <VariantSwatchStyles
        isActive={isActive}
        isValid={isValid}
        role="button"
        tabIndex={0}
        color={color}
        onClick={onClick}
      >
        {!color && description}
        {children}
      </VariantSwatchStyles>
      {color && isActive && (
        <VariantSwatchSelectionTextStyles>
          {description}
        </VariantSwatchSelectionTextStyles>
      )}
    </div>
  );
};
