import React, { FunctionComponent } from 'react';
import {
  StyledFieldWrap,
  StyledFieldCheckbox,
  StyledFieldCheckboxLabel,
} from './Checkbox.styles';

const FieldCheckboxLabel: FunctionComponent<{ label: string | string[] }> = ({
  label,
}) => {
  if (Array.isArray(label)) {
    return (
      <div>
        {label.map((l, index) => (
          <span key={index}>{l}</span>
        ))}
      </div>
    );
  } else if (typeof label === 'object') {
    return label;
  }

  return <span>{label}</span>;
};

interface CheckboxProps {
  square?: boolean;
  name?: string;
  id?: string;
  label?: string | string[];
  checked?: boolean;
  onClick?: () => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  id,
  label,
  ...restProps
}) => {
  return (
    <StyledFieldWrap>
      <StyledFieldCheckbox name={name} id={id} type="checkbox" {...restProps} />
      <StyledFieldCheckboxLabel {...restProps} htmlFor={id}>
        {label && <FieldCheckboxLabel label={label} />}
      </StyledFieldCheckboxLabel>
    </StyledFieldWrap>
  );
};
