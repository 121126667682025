import React from 'react';

import { icons, IconType } from 'components/Icons/Icons';
import { SanityRichText } from 'components/Common/SanityRichText';

import { SanityProductDescription } from 'graphql-types';
import {
  Container,
  Headline,
  Content,
  Section,
  SectionItem,
  SectionItemIcon,
  SectionItemIconWrapper,
  SectionItemText,
} from './ProductDescription.styles';

type ProductDescriptionProps = {
  description?: SanityProductDescription;
};

export const ProductDescription: React.FC<ProductDescriptionProps> = ({
  description,
}) => {
  if (!description) {
    return null;
  }

  return (
    <Container>
      <Headline>{description.title}</Headline>
      <Content>
        {description.sections?.map((section) => (
          <Section key={section?._key}>
            {section?.items?.map((sectionItem) => {
              const svgIcon =
                icons[(sectionItem?.icon as IconType) || 'dumbbell'];
              return (
                <SectionItem key={sectionItem?._key}>
                  {svgIcon && (
                    <SectionItemIconWrapper>
                      <SectionItemIcon {...svgIcon} />
                    </SectionItemIconWrapper>
                  )}
                  <SectionItemText>
                    <SanityRichText blocks={sectionItem?._rawText} />
                  </SectionItemText>
                </SectionItem>
              );
            })}
          </Section>
        ))}
      </Content>
    </Container>
  );
};
