import { WarningModal } from 'components/WarningModal/WarningModal';
import { SanityEntitledModal } from 'graphql-types';
import React, { FC } from 'react';
export const EntitledModal: FC<SanityEntitledModal> = ({
  title,
  paragraphCopy,
  ctaText,
  ctaLink,
}: SanityEntitledModal) => {
  return (
    <WarningModal
      body={paragraphCopy}
      ctaText={ctaText}
      ctaLink={ctaLink}
      title={title}
    />
  );
};
