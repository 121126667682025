import React, { createContext, useState, useContext, useMemo } from 'react';
import {
  Maybe,
  OpenfitBundleProductPageBySlugQuery,
  SanityOpenfitBundle,
} from 'graphql-types';
import {
  getBundleItemParamKey,
  useGetBundleGroupsFromProducts,
  getBundlePricingInfo,
  allBundleItems,
  selectedBundleItems,
  BundleProductItem,
} from './BundleProduct.helper';
import { getPriceFormatter } from '@bbnb/openfit-frontend-shared';
import { useUpdatedBottomLine, YotpoProductBottomLine } from 'helpers/Yotpo';
import { formatSavingsPercent } from 'helpers/General';
import { doesWindowExist } from '@bbnb/openfit-frontend-shared';

// #region Types
export type BundleProducts = NonNullable<
  NonNullable<
    NonNullable<OpenfitBundleProductPageBySlugQuery['page']>['bundle']
  >['products']
>;

export type BundleVariant = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<NonNullable<BundleProducts>[0]>['product']
    >['variants']
  >[0]
>;

export type BundleGroupMap = { [groupName: string]: BundleProducts };
export type BundleItemMap = {
  [productGroupName: string]: BundleVariant;
};

export type BundleItemDisplayNameMap = { [groupName: string]: string };
export type BundleDisplayMethod = 'allItems' | 'selectedItems';
// #endregion

export type SubscriptionInterval = {
  interval: string;
  intervalCount: number;
  recurring: boolean;
};
export type SubscriptionIntervalCollection = SubscriptionInterval[];

export type BundleProductState = {
  updateSelectedBundleItem: (
    productGroupName: string,
    item: BundleVariant
  ) => void;
  bundleProducts: BundleProducts;
  selectedBundleItemMap: BundleItemMap;
  setSelectedBundleItemMap: (bundleUpdateMap: BundleItemMap) => void;
  bundleGroupMap: BundleGroupMap;
  bundleItemDisplayNameMap: BundleItemDisplayNameMap;
  bundleItems: BundleProductItem[];
  review: Maybe<YotpoProductBottomLine>;
  reviewId: Maybe<string>;
  staticReviewData: Maybe<Partial<YotpoProductBottomLine>>;
  bundleId: string;
  displayMethod?: BundleDisplayMethod;
  subscriptionIntervals?: SubscriptionIntervalCollection;
  price: number;
  origPrice: number;
  selectedInterval: SubscriptionInterval | undefined;
  setSelectedInterval: (subscription: SubscriptionInterval) => void;
};

export type BundleProductContextState = {
  bundleType: BundleType;
  setBundleType: (bundleType: BundleType) => void;
  hasOneTimeBundle: boolean;
  savings: string;
  savingsPercent: string;
} & BundleProductState;

export type BundleType = 'default' | 'one-time';

// #endregion

export const BundleProductContext = createContext<BundleProductContextState>(
  {} as BundleProductContextState
);

export const useBundleProductContext = () => useContext(BundleProductContext);

export const useManageBundleState = (
  bundle?: SanityOpenfitBundle,
  displayMethod?: BundleDisplayMethod
): BundleProductState => {
  const {
    products,
    review,
    reviewId,
    staticReviewData,
    bundleId = '',
    discountAmount,
    discountType,
  } = (bundle || {}) as SanityOpenfitBundle;
  const bundleProducts = (products || []) as BundleProducts;

  const bundleReview = useUpdatedBottomLine(reviewId, review);

  // Get Bundle Group and Initial Map of selected variants
  const {
    bundleGroupMap = {},
    bundleItemMap: initialBundleItemMap = {},
    bundleItemDisplayNameMap = {},
    subscriptionIntervals,
  } = useGetBundleGroupsFromProducts(bundleProducts) || {};

  const [selectedBundleItemMap, setSelectedBundleItemMap] = useState(
    initialBundleItemMap
  );
  const [
    selectedInterval,
    setSelectedInterval,
  ] = useState<SubscriptionInterval>();

  const updateSelectedBundleItem = (
    productGroupName: string,
    item: BundleVariant
  ) => {
    const bundleItem = { [productGroupName]: { ...item } };
    setSelectedBundleItemMap({ ...selectedBundleItemMap, ...bundleItem });
  };

  const bundleItems = useMemo(
    () =>
      displayMethod === 'allItems'
        ? allBundleItems(bundleProducts)
        : selectedBundleItems(
            selectedBundleItemMap,
            bundleGroupMap,
            bundleItemDisplayNameMap
          ),
    [
      displayMethod,
      bundleProducts,
      selectedBundleItemMap,
      bundleGroupMap,
      bundleItemDisplayNameMap,
    ]
  );

  const { price = 0, origPrice = 0 } = useMemo(() => {
    return subscriptionIntervals
      ? getBundlePricingInfo(
          bundleItems,
          (discountType as 'percent') || 'percent',
          discountAmount || 0
        )
      : { price: 0, origPrice: 0 };
  }, [
    subscriptionIntervals,
    selectedBundleItemMap,
    discountType,
    discountAmount,
    bundleItems,
  ]);

  return {
    bundleItems,
    bundleProducts,
    bundleItemDisplayNameMap,
    selectedBundleItemMap,
    updateSelectedBundleItem,
    bundleGroupMap,
    review: bundleReview,
    reviewId,
    staticReviewData,
    bundleId,
    subscriptionIntervals,
    setSelectedBundleItemMap,
    price,
    origPrice,
    setSelectedInterval,
    selectedInterval,
  };
};

export const BundleProductProvider: React.FC<{
  bundle: SanityOpenfitBundle;
  oneTimeBundle?: SanityOpenfitBundle;
  displayMethod?: BundleDisplayMethod;
}> = ({ bundle, oneTimeBundle, displayMethod, children }) => {
  const defaultBundleState = useManageBundleState(bundle, displayMethod);
  const oneTimeBundleState = useManageBundleState(oneTimeBundle, displayMethod);

  const isDefaultOneTime =
    oneTimeBundle &&
    doesWindowExist() &&
    oneTimeBundleState?.selectedBundleItemMap &&
    Object.keys(oneTimeBundleState.selectedBundleItemMap).every((key) => {
      const searchParams = new URLSearchParams(window.location.search);
      const searchSku = searchParams.get(getBundleItemParamKey(key));
      return oneTimeBundleState.selectedBundleItemMap[key].sku === searchSku;
    });

  const [bundleType, internalSetBundleType] = useState<BundleType>(
    isDefaultOneTime ? 'one-time' : 'default'
  );

  const setBundleType = (newBundleType: BundleType) => {
    let oldState = defaultBundleState;
    let newState = oneTimeBundleState;

    if (
      newBundleType === 'default' &&
      oneTimeBundleState &&
      defaultBundleState
    ) {
      oldState = oneTimeBundleState;
      newState = defaultBundleState;
    }

    const bundleUpdates: {
      [productGroupName: string]: BundleVariant;
    } = {};
    Object.keys(oldState.selectedBundleItemMap).forEach((oldStateKey) => {
      const selectedVariant = oldState.selectedBundleItemMap[oldStateKey];

      const oldProduct = oldState.bundleProducts.find((product) =>
        product?.skus?.includes(selectedVariant.sku)
      );

      newState.bundleProducts?.find((product) => {
        const newProduct = product?.product;
        const variants = newProduct?.variants;

        if (oldProduct?.product?.name !== newProduct?.name) {
          return false;
        }

        const newVariantSelection =
          variants?.find((newVariant) => {
            return (
              newVariant?.name?.split('/')[0].trim() ===
              selectedVariant?.name?.split('/')[0].trim()
            );
          }) || variants?.[0];

        if (newVariantSelection) {
          bundleUpdates[oldStateKey] = newVariantSelection;
          return true;
        }
        return false;
      });
    });

    newState.setSelectedBundleItemMap({
      ...newState.selectedBundleItemMap,
      ...bundleUpdates,
    });

    internalSetBundleType(newBundleType);
  };

  const bundleState =
    bundleType === 'default'
      ? defaultBundleState
      : oneTimeBundleState || defaultBundleState;
  const format = getPriceFormatter('USD', 'en-US');

  const savings = oneTimeBundleState
    ? format(oneTimeBundleState.price - defaultBundleState.price)
    : format(defaultBundleState.price);
  const savingsPercent = formatSavingsPercent(
    defaultBundleState.price,
    oneTimeBundleState ? oneTimeBundleState.price : defaultBundleState.origPrice
  );

  return (
    <BundleProductContext.Provider
      value={{
        ...bundleState,
        displayMethod,
        bundleType,
        setBundleType,
        hasOneTimeBundle: Boolean(oneTimeBundle),
        savings,
        savingsPercent,
      }}
    >
      {children}
    </BundleProductContext.Provider>
  );
};
