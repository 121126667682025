import React from 'react';
import { Checkbox } from 'components/Common/Checkbox/Checkbox';
import {
  SavingsLabel,
  SubscribeAndSaveCheckboxContainer,
  SubscribeAndSaveContainer,
  SubscribeAndSaveDetails,
} from './SubscribeAndSave.styles';
import { VariantSwatch } from '../VariantSwatch/VariantSwatch';
import {
  BundleItemMap,
  BundleVariant,
  SubscriptionIntervalCollection,
  useBundleProductContext,
} from 'Context/BundleProduct/BundleProduct.context';
import { useIsBrowser } from '@bbnb/openfit-frontend-shared';
import { SubscriptionDuration } from 'components/SubscriptionDuration/SubscriptionDuration';
import styled from 'styled-components';

export const ServingTypeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  div:not(:last-child) {
    margin-right: 8px;
  }
`;

export const DeliveryTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.black};
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin-top: 16px;
  margin-bottom: 8px;
`;

function getActiveInterval(
  bundleItemMap: BundleItemMap,
  intervals: SubscriptionIntervalCollection
) {
  let intervalSourceVariant: BundleVariant | undefined;

  Object.keys(bundleItemMap).some((key) => {
    const bundleVariant = bundleItemMap[key];

    if (
      intervals.some(
        (intervalObject) =>
          intervalObject.interval === bundleVariant.interval &&
          intervalObject.intervalCount === bundleVariant.intervalCount
      )
    ) {
      intervalSourceVariant = bundleVariant;
      return true;
    }
    return false;
  });

  return intervalSourceVariant;
}

export const SubscribeAndSaveBundle: React.FC = () => {
  const {
    subscriptionIntervals,
    hasOneTimeBundle,
    bundleType,
    selectedBundleItemMap,
    bundleGroupMap,
    bundleProducts,
    setSelectedBundleItemMap,
    setSelectedInterval,
  } = useBundleProductContext();
  const isBrowser = useIsBrowser();

  if (!subscriptionIntervals) {
    return null;
  }

  const activeIntervalSource = getActiveInterval(
    selectedBundleItemMap,
    subscriptionIntervals
  );

  return (
    <SubscribeAndSaveContainer>
      {hasOneTimeBundle && <SubscribeAndSave />}
      {bundleType === 'default' &&
        Boolean(subscriptionIntervals.length > 1) &&
        activeIntervalSource && (
          <>
            <DeliveryTitle>Deliver Every:</DeliveryTitle>
            <ServingTypeContainer>
              {subscriptionIntervals.map((subcriptionInterval, idx) => {
                const { interval, intervalCount } = subcriptionInterval;
                return (
                  <VariantSwatch
                    key={idx + isBrowser.toString()}
                    isActive={
                      interval === activeIntervalSource.interval &&
                      intervalCount === activeIntervalSource.intervalCount
                    }
                    onClick={() => {
                      setSelectedInterval(subcriptionInterval);
                      if (interval && intervalCount) {
                        const bundleUpdates: {
                          [productGroupName: string]: BundleVariant;
                        } = {};

                        Object.keys(bundleGroupMap).forEach(
                          (bundleGroupName) => {
                            const item = selectedBundleItemMap[bundleGroupName];
                            const product = bundleProducts.find(
                              (product) =>
                                item.sku && product?.skus?.includes(item.sku)
                            );

                            const newBundleVariant =
                              item.interval !== interval ||
                              item.intervalCount !== intervalCount
                                ? product?.product?.variants?.find(
                                    (variant) =>
                                      variant &&
                                      variant.interval === interval &&
                                      variant.intervalCount === intervalCount &&
                                      variant.variantIngredient?.name ===
                                        item.variantIngredient?.name
                                  )
                                : undefined;
                            if (newBundleVariant) {
                              bundleUpdates[bundleGroupName] = newBundleVariant;
                            }
                          }
                        );

                        setSelectedBundleItemMap({
                          ...selectedBundleItemMap,
                          ...bundleUpdates,
                        });
                      }
                    }}
                  >
                    <SubscriptionDuration
                      omitPrefix={true}
                      variant={subcriptionInterval}
                    />
                  </VariantSwatch>
                );
              })}
            </ServingTypeContainer>
          </>
        )}
    </SubscribeAndSaveContainer>
  );
};

const SubscribeAndSave: React.FC = () => {
  const {
    setBundleType,
    bundleType,
    savings,
    savingsPercent,
  } = useBundleProductContext();

  const subscribeHandler = () => {
    if (bundleType === 'default') {
      setBundleType('one-time');
    } else {
      setBundleType('default');
    }
  };

  return (
    <SubscribeAndSaveCheckboxContainer onClick={subscribeHandler}>
      <Checkbox checked={Boolean(bundleType === 'default')} />
      <SubscribeAndSaveDetails>
        <div>Subscribe & Save an Additional {savingsPercent}</div>
        <SavingsLabel>Save an Additional {savings}</SavingsLabel>
      </SubscribeAndSaveDetails>
    </SubscribeAndSaveCheckboxContainer>
  );
};
