import React, { FC } from 'react';
import { EntitledModal } from './EntitledModal';
import { SanityEntitledModalFragment } from 'graphql-types';
import { UserRole } from '@bbnb/openfit-frontend-shared';

interface SanityEntitledModalProps {
  fields: SanityEntitledModalFragment;
  includesDigitalSubscription: boolean;
  role: UserRole;
}

export const SanityEntitledModal: FC<SanityEntitledModalProps> = ({
  fields,
  includesDigitalSubscription,
  role,
}) =>
  includesDigitalSubscription && role === 'subscriber' ? (
    <EntitledModal {...fields} />
  ) : null;
