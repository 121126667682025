import React from 'react';
import { renderBlockWithId } from 'helpers/block-with-id';
import { BlockType } from 'types';

import {
  BundleAddToCartBlockFragment,
  OpenfitBundleProductPageBySlugQuery,
  ProductReviewsBlockFragment,
  SanityEmailSignupBlockFragment,
  SanityEntitledModalFragment,
  SanityProductAboutFragment,
  SanityProductFaqFragment,
  SanityProductSliderFragment,
  SanitySellPageSpacer,
  SanitySimpleMediaWithTextBlockFragment,
  SanitySquareImageSliderFragment,
} from 'graphql-types';

import { ProductFAQSanity } from 'components/Product/ProductFAQ/ProductFAQSanity';
import { SanityEmailSignUp } from 'components/EmailSignUp/SanityEmailSignUp';
import { SimpleMediaWithText } from 'components/SimpleMediaWithText';
import { ProductAboutSanity } from 'components/Product/ProductAbout/ProductAbout.sanity';
import { SanityReviewSection } from 'components/Product/ReviewSection';
import { ProductSliderSanity } from 'components/Product/Slider/ProductSlider.sanity';
import { SanitySquareImageSlider } from 'components/SquareImageSlider/SanitySquareImageSlider';
import { Spacer } from 'components/Spacer/Spacer';
import { PDPPageContainer } from 'components/Pdp';
import { BundleItemList } from 'components/Product/BundleItemList';
import { BundleAddToCartBlock } from 'components/Product/BundleAddToCartBlock/BundleAddToCartBlock';
import { SanityEntitledModal } from 'components/Product/EntitledModal/SanityEntitledModal';
import { DEFAULT_BUY_BUTTON_ACTION } from 'Constants';
import { UserRole } from '@bbnb/openfit-frontend-shared';

export type BlocksArrayType = NonNullable<
  OpenfitBundleProductPageBySlugQuery['page']
>['blocks'];

export const renderBundlePageBlock = (
  block: BlockType<BlocksArrayType>,
  data: OpenfitBundleProductPageBySlugQuery['page'],
  includesDigitalSubscription: boolean,
  role: UserRole
) =>
  renderBlockWithId(
    block,
    getBlock(block, data, includesDigitalSubscription, role)
  );

function getBlock(
  block: BlockType<BlocksArrayType>,
  data: OpenfitBundleProductPageBySlugQuery['page'],
  includesDigitalSubscription: boolean,
  role: UserRole
) {
  const type = block.__typename;
  const id = block._key;

  const { name, bundle } = data || {};
  const { review, reviewId, staticReviewData, images, couponCode } =
    bundle || {};
  switch (type) {
    case 'SanityEmailSignupBlock':
      return (
        <SanityEmailSignUp
          key={id}
          fields={block as SanityEmailSignupBlockFragment}
        />
      );
    case 'SanitySimpleMediaWithTextBlock':
      return (
        <PDPPageContainer>
          <SimpleMediaWithText
            fields={block as SanitySimpleMediaWithTextBlockFragment}
          />
        </PDPPageContainer>
      );
    case 'SanitySellPageSpacer':
      return (
        <Spacer
          key={id}
          backgroundColor={
            (block as SanitySellPageSpacer)?.backgroundColor?.hex
          }
          margin={(block as SanitySellPageSpacer)?.margin}
          marginMobile={(block as SanitySellPageSpacer)?.marginMobile}
        />
      );
    case 'SanityBundleItemList':
      return <BundleItemList />;
    case 'SanityProductReviewsBlock':
      return (
        <SanityReviewSection
          reviewId={reviewId}
          review={review}
          staticReviewData={staticReviewData}
          product={{
            title: name,
            description: `Bundle ${name}`,
            image:
              images?.[0]?.image?.asset?.gatsbyImageData?.images?.fallback?.url, // todo [gatsby-plugin-image] - check into what we need to change that
          }}
          key={id}
          fields={block as ProductReviewsBlockFragment}
        />
      );
    case 'SanityProductSlider':
      return (
        <ProductSliderSanity
          key={id}
          fields={block as SanityProductSliderFragment}
        />
      );
    case 'SanityProductAbout':
      return (
        <ProductAboutSanity
          key={id}
          fields={block as SanityProductAboutFragment}
        />
      );
    case 'SanityProductFAQ':
      return (
        <ProductFAQSanity key={id} fields={block as SanityProductFaqFragment} />
      );
    case 'SanitySquareImageSlider':
      return (
        <SanitySquareImageSlider
          key={id}
          fields={block as SanitySquareImageSliderFragment}
        />
      );
    case 'SanityProductBundleAddToCart':
      return (
        <BundleAddToCartBlock
          key={id}
          title={name}
          images={images}
          couponCode={couponCode}
          description={(block as BundleAddToCartBlockFragment).description}
          addToCartAction={
            ((block as BundleAddToCartBlockFragment).buyButtonOption as
              | 'checkout'
              | 'cart') || DEFAULT_BUY_BUTTON_ACTION
          }
        />
      );

    case 'SanityEntitledModal':
      return (
        <SanityEntitledModal
          key={id}
          fields={block as SanityEntitledModalFragment}
          includesDigitalSubscription={includesDigitalSubscription}
          role={role}
        />
      );
    case 'SanityProp65Block':
      return null;

    default:
      return `Unrecognized block type: ${type}`;
  }
}
