import { useBundleProductContext } from 'Context/BundleProduct/BundleProduct.context';
import { useProductContext } from 'Context/Product/Product.context';
import React from 'react';
import { useMemo } from 'react';
import { useIsBrowser } from 'utils/general';
import { VariantSwatch } from '../VariantSwatch/VariantSwatch';
import {
  VariantGroupTitle,
  VariantSwatchContainer,
} from './VariantGroupSelector.styles';

export const VariantGroupSelector: React.FC = () => {
  const {
    variantGroupSelectors,
    selectedVariantGroup,
    setSelectedVariantGroup,
    variantGroupSelectionType,
  } = useProductContext();
  const { selectedInterval } = useBundleProductContext();

  const isBrowser = useIsBrowser();

  const variantGroupSwatches = useMemo(
    () =>
      variantGroupSelectors.map((variantGroup, idx) => {
        const { color, description } = variantGroup;
        const isActive = selectedVariantGroup?.color
          ? description === selectedVariantGroup.description
          : idx === 0;
        return (
          <VariantSwatch
            key={idx + isBrowser.toString()}
            isActive={isBrowser && isActive}
            color={color}
            onClick={() => {
              setSelectedVariantGroup(variantGroup, selectedInterval);
            }}
            description={description}
          />
        );
      }),
    [variantGroupSelectors, selectedVariantGroup, isBrowser, selectedInterval]
  );

  return (
    <div>
      <VariantGroupTitle>{variantGroupSelectionType}</VariantGroupTitle>
      <VariantSwatchContainer>{variantGroupSwatches}</VariantSwatchContainer>
    </div>
  );
};
