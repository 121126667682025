import React from 'react';
import { getPriceFormatter } from '@bbnb/openfit-frontend-shared';
import {
  Container,
  OriginalPriceText,
  CurrentPriceText,
  SavingsTitle,
} from './Savings.styles';

type SavingsProps = {
  originalPrice: number;
  currentPrice: number;
  savingsTitle?: string;
};

export const Savings: React.FC<SavingsProps> = ({
  currentPrice,
  originalPrice,
  savingsTitle = 'Total Savings',
}) => {
  const totalSavings = Math.round(originalPrice - currentPrice);
  const format = getPriceFormatter('USD', 'en-US', 'simple');
  return (
    <Container className="savings-block">
      <OriginalPriceText>
        <span className="savings-original-price">{format(originalPrice)}</span>
      </OriginalPriceText>
      <CurrentPriceText>
        <span
          className="savings-current-price"
          dangerouslySetInnerHTML={{
            __html: format(currentPrice)
              .replace(/(\$)/, '<sup>$1</sup>') // dolar sign
              .replace(/\.([0-9][0-9]?)/, '<sup>$1</sup>'), // cents
          }}
        ></span>
      </CurrentPriceText>
      <SavingsTitle>
        <span className="savings-title">{savingsTitle}</span>
        <br />
        <span className="savings-total">{format(totalSavings)}</span>
      </SavingsTitle>
    </Container>
  );
};
